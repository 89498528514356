import React from 'react'
import PropTypes from 'prop-types'
import Input from './Input'
import Text from './Text'
import Gap from './Gap'
import Col from './Col'

const TextField = ({ label, hasError, error, isFooter, width, maxWidth, ...props }) => {
  const InputComponent = isFooter ? Input.Dark : Input
  return (
    <Col width={width} maxWidth={maxWidth} position="relative">
      <InputComponent hasError={error || hasError} {...props} />
      {(error || hasError) && <Text.Error>{error || label}</Text.Error> }
    </Col>
  )
}

TextField.defaultProps = {
  hasError: false,
  label: '',
  error: '',
}

TextField.propTypes = {
  hasError: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
}

export default TextField
